
































































@use "sass:math";
@import '@design';

$hover-border-w: 2px;
.menu-item {
  position: relative;
  transition: background-color 0.2s ease, transform 0.2s ease;
  &.standard {
    .menu-item--top {
      > * {
        line-height: 1.3;
      }
    }
  }
  &.condensed {
    .menu-item--top {
      > * {
        line-height: 0.8;
      }
    }
    .item-price {
      font-weight: bold;
    }
  }

  .menu-item--title,
  .menu-item--prices {
    font-size: 1.1em;
    text-transform: uppercase;
    letter-spacing: 0.046em;
  }
  .menu-item--description {
    font-size: 0.95em;
    font-weight: 500;
    line-height: 1.3;
  }
  &.sortable-ghost {
    background-color: rgba($color-db-green, 0.2);
    opacity: 0.4;
  }
  &.showing-ui {
    &.hovering,
    &.selected {
      $gutter: 20px;

      padding-right: $gutter;
      padding-left: math.div($gutter, 2);
      margin-right: -$gutter;
      margin-left: math.div(-$gutter, 2);
    }
  }
  &.hovering.showing-ui {
    background-color: rgba($color-db-teal, 0.5);
  }
  &.selected {
    background-color: rgba($color-db-green, 0.1);
  }
  &.delete-hover {
    background: rgba(red, 0.075) !important;
  }
  &.type--sides {
    .menu-item--separator {
      margin-right: 0.23em;
      margin-left: 0.15em;
      font-size: 2.4em;
      font-weight: 900;
      line-height: 0.6em;
    }
  }
}

.menu-element--dots {
  margin-bottom: 3px;
  border-bottom: 2px dotted #000;
}

.drag-handle--wrap {
  position: absolute;
  top: 50%;
  right: 0;
  min-width: 20px;
  height: 100%;
  transform: translateY(-50%);
  .drag-handle {
    font-size: 18px;
    cursor: pointer;
  }
}
